.DefaultLayout__main {
	margin-left:  0;
}

@media only screen and (min-width: 800px) {

	.DefaultLayout__main {
		margin-left:  250px;
		padding:  30px;
	}

}