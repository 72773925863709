.OrderPartListItem {
	display:  flex;
	justify-content: space-between;
	background-color:  #FFF;
	margin:  20px 0;
	border-radius: 4px;
	border:  1px solid #E0E0E0;
	background-image:  url('../../../../assets/chevron-right.svg');
	background-position: right 20px bottom 20px;
	transition: all 0.5s ease;
}

.OrderPartListItem:hover {
	box-shadow: 0 0 5px 3px rgba(0,0,0,0.10);
	background-position: right 15px bottom 20px
}

.OrderPartListItem__left {
	flex:  1;
	padding:  15px;
}

.OrderPartListItem__right {
	padding:  15px;
	text-align: right;
}

.OrderPartListItem__name {
	font-weight:  600;
	margin-bottom: 5px;
}

.OrderPartListItem__delivery {
	margin-bottom: 5px;
}

.OrderPartListItem__price {
	font-size:  15px;
	font-weight:  600;
}