.Accordian {
	border: 1px solid #E0E0E0;
	border-radius: 4px;
	margin-bottom:  20px;
}

.Accordian__header {
	display:  flex;
	align-items: center;
	cursor: pointer;
	padding:  10px;
	background-image: url('../../../assets/chevron-down.svg');
	background-position: center right 20px;
}

.Accordian--open .Accordian__header {
	border-bottom:  1px solid #E0E0E0;
	background-image: url('../../../assets/chevron-up.svg');
}

.Accordian__header__content {
	margin-left:  30px;
	opacity: 0.8
}

.Accordian__header__title {
	font-weight: 600;
}

.Accordian__body {
	padding:  10px;
}