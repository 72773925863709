
/* Fonts
-----------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


/* Resetter
-----------------------------------------------*/

* {
	margin: 0;
	padding: 0;
	list-style-type: none;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	background-repeat: no-repeat;
}


/* HTML and Body
-----------------------------------------------*/

body {
	font-family: 'Poppins', sans-serif;
	color: #2A3042;
	padding:  20px;
	font-weight:  500;
	font-size:  14px;
}


/* Paragraphs and Anchors
-----------------------------------------------*/

p {
	margin:  25px 0;
}

a {
	text-decoration: none;
	color: inherit;
}


/* Buttons
-----------------------------------------------*/

.button {
	display: block;
	background-color: #02A4AE;
	padding:  15px;
	font-size: 18px;
	color:  #FFF;
	border-radius: 4px;
	text-align: center;
	font-weight: 600;
	border:  0;
	cursor: pointer;
	width: 100%;
	transition: all 0.5s ease;
}

.button--grey {
	background-color: #999;
}

.button--green {
	background-color: #76B82A;
}

.button--red {
	background-color:  #BC2354;
}

.button:hover {
	opacity: 0.75;
}

.button--fixed-bottom {
	position: fixed;
	bottom: 20px;
	left:  20px;
	right: 20px;
}

.button--small {
	padding:  7px 15px;
	font-size:  15px;
}

.button--centered {
	margin:  0 auto;
	display:  inline-block;
	width:  auto;
}

.button--outlined {
	border:  2px solid #02A4AE !important;
	background-color: #FFF;
	color:  #02A4AE;
}

.button--text {
	padding:  0;
	background-color:  transparent;
	color:  #02A4AE;
	font-size:  15px;
	border:  0;
}

.button--left-text {
	text-align: left;
}

.button--disabled {
	opacity:  0.3;
	cursor: not-allowed;
}

.button--disabled:hover {
	opacity:  0.3;
}

.button--back {
	flex:  0 1 40px;
	height:  40px;
	background-color:  #BAC9C8;
	background-position: center center;
	background-image:  url('back.svg');
}

.button--inline {
	display:  inline-block;
	width:  auto;
}


.button--loading {
	background-image: 
	    repeating-linear-gradient(
	      -45deg, 
	      #F6B21F, 
	      #F6B21F 1rem,
	      #F3A600 1rem,
	      #F3A600 2rem
	    );
  background-size: 200% 200%;
  animation: loadingButton 10s linear infinite;
}

@keyframes loadingButton {
  100% {
    background-position: 100% 100%;
  }
}


/* Icons with text (basically buttons)
-----------------------------------------------*/

.icon {
	display:  block;
	background-repeat: no-repeat;
	background-position: left center;
	font-weight: 500;
	padding:  10px 0 10px 30px;
	margin:  10px 0;
	color:  #02A4AE;
	transition: all 0.5s ease;
}

.icon--plus {
	background-image: url('plus-blue.svg');
	padding-left:  40px;
}

.icon--rocket {
	background-image: url('rocket.svg');
}

.icon:hover {
	opacity:  0.65;
}


/* Status pills
-----------------------------------------------*/

.status {
	display: inline-block;
	text-transform: uppercase;
	border:  2px solid #777;
	border-radius: 400px;
	font-size:  13px;
	font-weight:  600;
	padding:  1px 10px 0px;
}

.status--red {
	color:  #BC2354;
	border:  2px solid #BC2354;
}

.status--orange {
	color:  #EE7523;
	border:  2px solid #EE7523;
}

.status--green {
	color:  #76B82A;
	border:  2px solid #76B82A;
}


/* Boxed messages
-----------------------------------------------*/

.message {
	padding:  10px;
	border-radius: 4px;
}

.message--green {
	background-color:  #E6F2D9;
	color:  #477611;
	border-left:  3px solid #76B82A;
}

.message--orange {
	background-color:  #FBE2D1;
	color:  #EE7523;
	border-left:  3px solid #EE7523;
}


/* Temp Report Table
-----------------------------------------------*/

.report-table {
	width: 100%;
	border-collapse: collapse;
}

.report-table th {
	border-bottom:  2px solid #E2E2E2;
	padding:  5px;
	text-align:  left;
	font-size:  14px;
}

.report-table td {
	text-align:  left;
	border-bottom:  1px solid #E2E2E2;
	padding:  5px;
	font-size:  14px;
}

.report-table th.--right, .report-table td.--right {
	text-align:  right;
}



.table {
	width:  100%;
	border-collapse: collapse;
}

.table td {
	padding:  10px;
}

.table tr:nth-child(even) td {
	background-color:  #F3F5F7;
}

.table__row__column--right {
	text-align: right;
}

small {
	opacity: 0.5;
	font-size:  13px;
}


/* Text Styles
-----------------------------------------------*/

.orange-text {
	color:  #EE7523;
}

.green-text { 
	color:  #76B82A;
}

.red-text {
	color:  #BC2354;
}

.grey-text {
	color:  #868686;
}

.small-text {
	font-size:  14px;
}



@media only screen and (min-width: 800px) {

	body {
		padding:  0;
	}

}