.OrderListItem {
	margin:  25px 0;
	background: #FFFFFF;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	transition: all 0.5s ease;
	background-image:  url('../../../../assets/chevron-right.svg');
	background-repeat: no-repeat;
	background-position: right 20px bottom 20px;
	min-height:  150px;
}

.OrderListItem:hover {
	box-shadow: 0 0 5px 3px rgba(0,0,0,0.10);
	background-position: right 15px bottom 20px
}

.OrderListItem__header {
	display:  flex;
	align-items: center;
	justify-content: space-between;
	border-bottom:  1px solid #E0E0E0;
	padding:  10px 15px;
	color:  #838383;
	font-size:  14px;
}

.OrderListItem__body {
	display:  flex;
	justify-content: space-between;
	padding:  15px;
}

.OrderListItem__body__right {
	text-align: right;
}

.OrderListItem__price {
	font-weight:  600;
}

.OrderListItem__items {
	color:  #9C9C9C;
	font-size:  12px;
}