.Header
{
	display:  flex;
	align-items: center;
	justify-content: space-between;
	text-align: center;	
	margin-bottom:  30px;
}

.Header__logo {
	display:  block;
	width:  110px;
	height:  20px;
	background-image: url(assets/logo.svg);
}

@media only screen and (min-width: 800px) {

	.Header {
		height:  100px;
		margin-left:  250px;
		padding:  30px;
		justify-content: flex-end;
		margin-bottom:  0px;
	}

	.Header__logo {
		display:  none;
	}


}