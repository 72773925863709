.Footer {
	position: fixed;
	bottom: 0px;
	right:  0px;
	left:  0px;
	background-color: #FFF;
	padding:  20px;
}

.FooterRow {
	display: flex;
	align-items: center;
}

.FooterRow > * {
	flex:  1;
}

.Footer .button {
	margin-right:  20px;
}

.Footer .button:last-child {
	margin-right:  0;
}

@media only screen and (min-width: 800px) {

	.Footer {
		margin-left:  250px;
	}

}