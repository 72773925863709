
.thingstodo {
	display: flex;
	flex-wrap: wrap;
}

.thingstodo__item {
	flex:  0 1 50%;
	margin:  5px 0;
}

.thingstodo__item__link {
	padding:  5px 5px 5px 25px;
	color:  #02A4AE;
	font-weight:  600;
	background-position: left center;
}


.thingstodo__item__link--orange {
	color:  #EE7523;
}

.thingstodo__item__link--red {
	color:  #BC2354;
}

.thingstodo__item__link--edit {
	background-image:  url('assets/pen.svg');
}

.thingstodo__item__link--customer {
	background-image:  url('assets/customer.svg');
}

.thingstodo__item__link--return {
	background-image:  url('assets/return.svg');
}

.thingstodo__item__link--cancel {
	background-image:  url('assets/cancel.svg');
}