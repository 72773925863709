.EmptyState {
	margin:  40px 0;
	text-align: center;
}

.EmptyState__title {
	font-weight:  600;
	font-size:  16px;
}

.EmptyState__description {
	color: #7F838E;
	font-size:  14px;
	max-width:  300px;
	margin: 10px auto 20px;
}

.EmptyState__icon {
	margin-bottom:  10px;
}

.EmptyState__icon img {
	width:  70px;
}

