

.SummaryLine {
	display:  flex;
	justify-content: space-between;
	border-bottom:  1px solid #DDDDDD;
	padding: 10px 0;
}

.SummaryLine__title1 {
	font-size:  16px;
}

.SummaryLine__value1 {
	text-align: right;
	font-weight:  600;
	font-size:  18px;
}

.SummaryLine__title2, .SummaryLine__value2 {
	color:  #A1A4AC;
	font-size:  14px;
}

.SummaryLine__left {
	flex:  1;
	margin-right:  20px;
}

.SummaryLine__right {
	flex:  0.3;
	text-align:  right;
}