
.Title {
	margin-bottom:  20px;
}

.Title__header {
	display:  flex;
	align-items: center;
	justify-content: space-between;
}

.Title__header__action .icon {
	margin:  0;
}

h1 {
	font-size:  20px;
	font-weight:  500;
}

h2 {
	font-size:  16px;
	color:  #A2A5AD;
	font-weight:  500;
}