
.Hamburger {
	width:  37px;
	cursor: pointer;
}


.Hamburger__bar {
	background-color:  #BDBDBD;
	height:  2px;
	margin-bottom:  8px;
	border-radius: 5px;
}

.Hamburger .Hamburger__bar:last-child {
	margin-bottom:  0;
}

.Hamburger__bar:nth-child(2) {
	width:  30px;
}

.Hamburger__bar:nth-child(3) {
	width:  23px;
}

.Hamburger:hover .Hamburger__bar {
	background-color:  #444
}

@media only screen and (min-width: 800px) { 

	.Hamburger {
		display:  none;
	}

}