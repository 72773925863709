.Navigation__panel {
	background-color:  #FFF;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 250px;
	box-shadow: 0 0 13px 0 rgba(0,0,0,0.50);
	padding: 30px;
	margin-left: -270px;
	z-index: 2;
	transition: all 0.5s ease;
}

.Navigation--active .Navigation__panel {
	margin-left:  0;
}

.Navigation__List {
	margin-top:  20px;
}

.Navigation__list__item__link {
	display:  block;
	color:  #2A3042;
	font-size:  15px;
	padding:  10px 0;
}

.Navigation__Overlay {
	position:  fixed;
	top:  0;
	bottom:  0;
	width:  100%;
	right:  0;
	background-color:  rgba(0, 0, 0, 0.50);
	z-index:  1;
	margin-right:  -100%;
	transition: all 0.5s ease;
}

.Navigation--active .Navigation__Overlay {
	margin-right:  0;
}

.Navigation__header {
	display: flex;
	justify-content: space-between;
}

.Navigation__header__emblem {
	width:  53px;
	height:  50px;
	background-image:  url('../../../assets/emblem.svg');
}

.Navigation__header__close {
	cursor: pointer;
	opacity:  0.5;
}


@media only screen and (min-width: 800px) {

	.Navigation__panel {
		margin-left:  0;
		background-color:  #2A3042;
		box-shadow: none;
		padding:  0;
	}

	.Navigation__header__close {
		display:  none;
	}

	.Navigation__List {
		margin:  75px 30px 30px 30px;
	}

	.Navigation__list__item__link {
		color:  #FFF;
		text-align: center;
	}

	.Navigation__Overlay {
		display:  none;
	}

	.Navigation__header__emblem {
		width:  100%;
		height:  100px;
		background-image:  url('../../../assets/tropic-logo-white.svg');
		background-position:  center;
		background-size:  110px 21px;
	}

}